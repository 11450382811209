import React from "react";
import toast from "react-hot-toast";
import { IoDice, IoOptions, IoSearch } from "react-icons/io5";
import { useParams } from "react-router";
import {
  filterAlbums,
  getRandomAlbumId,
  showModal,
} from "../Constants/helpers";
import { trackButtonClick, trackModalOpen } from "../Constants/mixpanel";
import { auth } from "../firebase";
import ShareList from "./ShareList";

type Props = {
  albums: Album[];
  filterOption: FilterType;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ListHeader({
  albums,
  filterOption,
  setShowSearch,
}: Props) {
  const { uid } = useParams<{ uid: string }>();

  const signedInUserTheirListHeader = (
    <div className="flex">
      <ShareList />
      <button
        className="cursor-pointer"
        onClick={() => {
          setShowSearch((prev) => !prev);
        }}
      >
        <IoSearch
          title={"search"}
          className="h-7 w-7 mr-5 text-text-base active:text-text-active"
        />
      </button>
      <button
        className="cursor-pointer"
        onClick={() => {
          const filteredAlbums = filterAlbums(filterOption, albums);

          if (filteredAlbums.length === 0) {
            toast.error("No Albums to select from");
            return;
          }

          const randomAlbumId = getRandomAlbumId(filteredAlbums);

          showModal(`modal-${randomAlbumId}`);
          trackModalOpen("Random Album");
        }}
      >
        <IoDice
          title={"search"}
          className="h-7 w-7 mr-5 text-text-base active:text-text-active"
        />
      </button>
      <button
        className="cursor-pointer"
        onClick={() => {
          showModal("modal-options");
          trackModalOpen("List Options");
        }}
      >
        <IoOptions
          title={"search"}
          className="h-7 w-7 text-text-base active:text-text-active"
        />
      </button>
    </div>
  );

  const signedInUserNotTheirListHeader = (
    <a
      className="btn btn-sm w-36 btn-outline text-text-base"
      href={auth.currentUser?.uid}
      onClick={() => trackButtonClick("Go to my list")}
    >
      Go To My List
    </a>
  );

  const notSignedInHeader = (
    <a
      className="btn btn-sm w-36 btn-outline text-text-base"
      href="/landing"
      onClick={() => trackButtonClick("Sign up button clicked on list page")}
    >
      Sign Up
    </a>
  );

  const header = () => {
    if (auth.currentUser?.uid === uid) {
      return signedInUserTheirListHeader;
    } else if (auth.currentUser) {
      return signedInUserNotTheirListHeader;
    } else {
      return notSignedInHeader;
    }
  };

  return <div>{header()}</div>;
}
