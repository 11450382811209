import React from "react";
import { IoStar, IoStarHalf } from "react-icons/io5";

type Props = {
  image: string;
  name: string;
  artist: string;
  rating?: number;
};

export default function Album({ image, name, artist, rating }: Props) {
  const fullStars = Math.floor(rating || 0);
  const halfStar = (rating || 0) % 1 >= 0.5;

  return (
    <div className="flex items-center mb-4 w-full cursor-pointer group">
      <img src={image} width="50" height="50" alt="" className={"mr-3"} />
      <div className="flex flex-col items-start">
        <div className="flex flex-row items-center">
          <p className="text-base text-text-base group-active:text-text-active mr-2">
            {name.length > 20 ? name.slice(0, 18) + "..." : name}
          </p>
          {rating ? (
            <div className="badge bg-background-highlight border-none px-1.5">
              {[...Array(fullStars)].map((_, i) => (
                <IoStar className="h-3 w-3 text-yellow-500" key={i} />
              ))}
              {halfStar && <IoStarHalf className="h-3 w-3 text-yellow-500" />}
            </div>
          ) : null}
        </div>
        <p className="text-xs text-text-subdued group-active:text-text-active">
          {artist.length > 30 ? artist.slice(0, 30) + "..." : artist}
        </p>
      </div>
    </div>
  );
}
