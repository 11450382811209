import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { IoStar, IoStarHalf, IoStarOutline } from "react-icons/io5";
import { useParams } from "react-router";
import { trackButtonClick } from "../Constants/mixpanel";
import { auth, firestore } from "../firebase";

type Props = {
  album: Album;
  albums: Album[];
  setAlbums: React.Dispatch<React.SetStateAction<Album[]>>;
};

export default function StarRating({ album, albums, setAlbums }: Props) {
  const { uid } = useParams<{ uid: string }>();
  const [rating, setRating] = useState(album.rating || 0);
  const starStyle = "h-6 w-6";

  useEffect(() => {
    setRating(album.rating || 0);
  }, [album]);

  const setRatingDB = async (rating: number) => {
    setRating(rating);
    setDoc(
      doc(firestore, `users/${auth.currentUser!.uid}/listenList/${album.id}`),
      {
        rating,
      },
      { merge: true },
    );
    const newAlbums = albums.map((a: Album) =>
      a.id === album.id ? { ...a, rating } : a,
    );
    localStorage.setItem("albums", JSON.stringify(newAlbums));
    setAlbums(newAlbums);
    trackButtonClick("Set album rating", {
      rating: rating,
      album: { name: album.name, artist: album.artist, id: album.id },
    });
  };

  const StarComponent = ({ index }: { index: number }) => {
    return (
      <div
        className="pr-1 cursor-pointer"
        onClick={() => {
          if (auth.currentUser?.uid !== uid) {
            return;
          }

          if (index !== rating) {
            setRatingDB(index);
          } else if (index === rating) {
            setRatingDB(index - 0.5);
          }
        }}
      >
        {index <= rating ? (
          <IoStar className={starStyle + " text-yellow-500"} />
        ) : index - 0.5 === rating ? (
          <IoStarHalf className={starStyle + " text-yellow-500"} />
        ) : (
          <IoStarOutline className={starStyle + " text-text-subdued"} />
        )}
      </div>
    );
  };

  return (
    <div className="flex items-center mb-8">
      <div className="flex mr-4">
        <StarComponent index={1} />
        <StarComponent index={2} />
        <StarComponent index={3} />
        <StarComponent index={4} />
        <StarComponent index={5} />
      </div>
      {auth.currentUser?.uid === uid && rating > 0 && (
        <button
          className="btn btn-xs bg-background-hover text-text-subdued border-none"
          onClick={() => {
            setRatingDB(0);
          }}
        >
          Clear
        </button>
      )}
    </div>
  );
}
