import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Album from "../Components/Album";
import SearchBar from "../Components/SearchBar";
import { persistAlbum } from "../Constants/helpers";
import { trackButtonClick } from "../Constants/mixpanel";
import Page from "../Containers/Page";

export default function SearchPage() {
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState("");

  useEffect(() => {
    if (text !== "") {
      const delayDebounceFn = setTimeout(() => {
        getSuggestions(text).then((s: any) => setSuggestions(s));
      }, 200);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [text]);

  const getSuggestions = async (value: string) => {
    try {
      const res = await axios.get(
        `https://api.spotify.com/v1/search?q=${value.replace(
          " ",
          "%20",
        )}&type=album&limit=10`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("spotify_token"),
          },
        },
      );
      return res.data.albums.items;
    } catch (e) {
      Sentry.captureException(e, {
        tags: { api: "https://api.spotify.com/v1/search" },
      });
      console.error(e);
      return [];
    }
  };

  const addAlbum = async (album: any) => {
    trackButtonClick("Add album to listen list", {
      album: { name: album.name, artist: album.artist, id: album.id },
    });
    persistAlbum(album);
    toast.success(
      `${
        album.name.length > 23 ? album.name.slice(0, 23) + "..." : album.name
      } Added!`,
    );
  };

  return (
    <Page>
      <div className="flex justify-between mb-4">
        <p className="text-2xl text-text-base w-full">Search</p>
      </div>
      <SearchBar searchText={text} setSearchText={setText} />
      {suggestions.map((suggestion: any, i: number) => {
        return (
          <div key={i} onClick={() => addAlbum(suggestion)}>
            <Album
              image={suggestion.images[1].url}
              name={suggestion.name}
              artist={suggestion.artists[0].name}
            />
          </div>
        );
      })}
    </Page>
  );
}
