import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Tabs from "../Components/Tabs";
import { auth } from "../firebase";

export default function Page({ children }: any) {
  const [user] = useAuthState(auth);

  return (
    <>
      <div className="bg-background-base max-w-xl mx-auto">
        <div className="p-4 pt-14">
          {children}
          <div className="h-40" />
        </div>
      </div>
      {user && <Tabs />}
    </>
  );
}
