import { onAuthStateChanged } from "firebase/auth";
import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Switch, useHistory } from "react-router";
import { AppProvider } from "./Components/AppContext";
import { getRefreshToken } from "./Constants/helpers";
import CallbackPage from "./Pages/CallbackPage";
import LandingPage from "./Pages/LandingPage";
import ListPage from "./Pages/ListPage";
import ProfilePage from "./Pages/ProfilePage";
import SearchPage from "./Pages/SearchPage";
import SplashPage from "./Pages/SplashPage";
import { auth } from "./firebase";

function App() {
  const history = useHistory();

  mixpanel.init("1d9d13675e474d734ccb178c721836ea", {
    track_pageview: true,
    persistence: "localStorage",
  });

  // Handle Google Auth
  onAuthStateChanged(auth, (user) => {
    if (user) {
      mixpanel.identify(user.uid);
    } else {
      mixpanel.identify();
    }
  });

  // Handle Spotify Auth
  useEffect(() => {
    const spotifyRefreshToken = localStorage.getItem("spotify_refresh_token");

    if (!spotifyRefreshToken || spotifyRefreshToken === "undefined") {
      return;
    }

    getRefreshToken(spotifyRefreshToken).then((response) => {
      if (response === null) {
        localStorage.removeItem("spotify_refresh_token");
        history.push("/");
      } else {
        localStorage.setItem("spotify_token", response.spotify_token);
        localStorage.setItem(
          "spotify_refresh_token",
          response.spotify_refresh_token,
        );
      }
    });
  }, [history]);

  return (
    <AppProvider>
      <main className="text-text-base bg-background-base">
        <Switch>
          <Route path="/" component={SplashPage} exact />
          <Route path="/landing" component={LandingPage} exact />
          <Route path="/callback" component={CallbackPage} exact />
          <Route path="/list/:uid" component={ListPage} exact />
          <Route path="/search" component={SearchPage} exact />
          <Route path="/profile" component={ProfilePage} exact />
        </Switch>
        <Toaster
          position="top-center"
          toastOptions={{
            duration: 3000,
            style: {
              background: "#444444",
              color: "#ffffff",
            },
          }}
        />
      </main>
    </AppProvider>
  );
}

export default App;
