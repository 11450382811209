import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { backendBaseUrl } from "../Constants/constants";
import Modal from "../Containers/Modal";
import { auth } from "../firebase";

export default function FeedbackModal() {
  const [email, setEmail] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  const onSubmit = async () => {
    try {
      await axios.post(`${backendBaseUrl}/feedback`, {
        email,
        feedback: feedbackText,
        userId: auth.currentUser?.uid,
      });
      setFeedbackText("");
      setEmail("");
      toast.success("Feedback submitted. Thanks for helping me improve!");
    } catch (e) {
      toast.error("Feedback failed to submit. Please try again later.");
      Sentry.captureException(e, {
        tags: { api: `${backendBaseUrl}/feedback` },
      });
      console.error(e);
    }
  };

  return (
    <Modal modalId="feedback-modal" size="large">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl text-text-base">Send Me Feedback</h2>
        <p className="text-sm text-text-subdued">
          Notice something isn't working correctly or got an idea for how I
          could make Album Listen List better? Type it in below and I'll get
          back to you ASAP!
        </p>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email e.g. john@gmail.com (optional)"
          className="input input-sm bg-background-base placeholder:text-neutral-600"
        />
        <textarea
          className="textarea textarea-sm placeholder:text-neutral-600 bg-background-base h-28"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          placeholder="Your feedback or idea here!"
        ></textarea>
        <form method="dialog">
          <button
            className="btn bg-background-base text-spotify-green"
            disabled={feedbackText === ""}
            onClick={onSubmit}
          >
            Submit Feedback
          </button>
        </form>
      </div>
    </Modal>
  );
}
