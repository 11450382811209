import React from "react";
import {
  IoEllipsisVertical,
  IoPhonePortraitOutline,
  IoShareOutline,
} from "react-icons/io5";
import { MdAddBox } from "react-icons/md";
import { getOS } from "../Constants/helpers";
import Modal from "../Containers/Modal";

export default function InstallModal() {
  return (
    <Modal modalId="install-modal">
      <p className="text-2xl text-text-base mb-2">Add to Home Screen</p>
      <p className="text-sm text-text-subdued mb-10">
        This website has app functionality. Add it to your home screen to use it
        in fullscreen.{" "}
        {getOS() === "iOS"
          ? "(make sure you're using Safari)"
          : getOS() === "Android" && "(make sure you're using Chrome)"}
        .
      </p>

      {getOS() !== "Desktop" ? (
        <>
          <div className="flex items-center justify-start mb-6">
            {getOS() === "iOS" ? (
              <>
                <IoShareOutline
                  title={"share"}
                  className="h-8 w-8 text-blue-500"
                />
                <p className="text-sm text-text-base ml-3">
                  1) Press the 'Share' button below
                </p>
              </>
            ) : getOS() === "Android" ? (
              <>
                <IoEllipsisVertical
                  title={"share"}
                  className="h-8 w-8 text-white"
                />
                <p className="text-sm text-text-base ml-3">
                  1) Press the 'Options' button at the top
                </p>
              </>
            ) : null}
          </div>

          <div className="flex items-center justify-start">
            <MdAddBox className="h-8 w-8 text-text-subdued" />
            <p className="text-sm text-text-base ml-3">
              2) Press 'Add to Home Screen'
            </p>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-start mb-6">
          <div className="flex items-center justify-start">
            <IoPhonePortraitOutline className="h-8 w-8 text-text-subdued" />
            <p className="text-sm text-text-base ml-3">
              This is a mobile only feature, check out Album Listen List on your
              phones browser!
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
}
