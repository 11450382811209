import React from "react";
import { AiFillHome, AiOutlineHome } from "react-icons/ai";
import {
  IoPerson,
  IoPersonOutline,
  IoSearch,
  IoSearchOutline,
} from "react-icons/io5";
import { useHistory } from "react-router";
import { trackButtonClick } from "../Constants/mixpanel";
import { auth } from "../firebase";

export default function Tabs() {
  const history = useHistory();

  const TabContainer = ({ children, route }: any) => {
    return (
      <div
        className="flex flex-col items-center px-10 cursor-pointer"
        onClick={() => {
          trackButtonClick("Tab clicked", {
            tab: route.replace(/^\/([^/]*).*$/, "$1"),
          });
          history.push(route);
        }}
      >
        {children}
      </div>
    );
  };

  const TabText = ({ text, selected }: { text: string; selected: boolean }) => {
    return (
      <p
        className={`text-[10px] mt-1 ${
          selected ? "text-text-base" : "text-text-subdued"
        }`}
      >
        {text}
      </p>
    );
  };

  return (
    <div className="fixed bottom-0 w-full">
      <div className="h-24 bg-gradient-to-t from-background-base to-transparent" />
      <div className="bg-background-base flex max-w-xl mx-auto justify-evenly pb-10 h-24">
        <TabContainer route={"/list/" + auth.currentUser?.uid}>
          {window.location.pathname === "/list/" + auth.currentUser?.uid ? (
            <AiFillHome color="#ffffff" title={"list"} className="h-7 w-7" />
          ) : (
            <AiOutlineHome color="#a7a7a7" title={"list"} className="h-7 w-7" />
          )}
          <TabText
            text="Home"
            selected={
              window.location.pathname === "/list/" + auth.currentUser?.uid
            }
          />
        </TabContainer>
        <TabContainer route="/search">
          {window.location.pathname === "/search" ? (
            <IoSearch color="#ffffff" title={"search"} className="h-7 w-7" />
          ) : (
            <IoSearchOutline
              color="#a7a7a7"
              title={"search"}
              className="h-7 w-7"
            />
          )}
          <TabText
            text="Search"
            selected={window.location.pathname === "/search"}
          />
        </TabContainer>
        <TabContainer route="/profile">
          {window.location.pathname === "/profile" ? (
            <IoPerson color="#ffffff" title={"profile"} className="h-7 w-7" />
          ) : (
            <IoPersonOutline
              color="#a7a7a7"
              title={"profile"}
              className="h-7 w-7"
            />
          )}
          <TabText
            text="Profile"
            selected={window.location.pathname === "/profile"}
          />
        </TabContainer>
      </div>
    </div>
  );
}
