import React from "react";
import { persistValue } from "../Constants/helpers";
import { trackButtonClick } from "../Constants/mixpanel";
import { topButtonSelectedStyle, topButtonStyle } from "../Constants/styles";

type Props = {
  filterOption: FilterType;
  setFilterOption: React.Dispatch<React.SetStateAction<FilterType>>;
};

export default function FilterButtons({
  filterOption,
  setFilterOption,
}: Props) {
  return (
    <div className="flex flex-row gap-2 mb-6">
      <button
        className={
          filterOption === "all" ? topButtonSelectedStyle : topButtonStyle
        }
        onClick={() => {
          setFilterOption("all");
          persistValue("filterOption", "all");
          trackButtonClick("Filter all selected");
        }}
      >
        All
      </button>
      <button
        className={
          filterOption === "not-reviewed"
            ? topButtonSelectedStyle
            : topButtonStyle
        }
        onClick={() => {
          setFilterOption("not-reviewed");
          persistValue("filterOption", "not-reviewed");
          trackButtonClick("Filter not reviewed selected");
        }}
      >
        Not reviewed
      </button>
      <button
        className={
          filterOption === "reviewed" ? topButtonSelectedStyle : topButtonStyle
        }
        onClick={() => {
          setFilterOption("reviewed");
          persistValue("filterOption", "reviewed");
          trackButtonClick("Filter reviewed selected");
        }}
      >
        Reviewed
      </button>
    </div>
  );
}
