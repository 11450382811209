import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { IoMusicalNotes } from "react-icons/io5";
import { useHistory } from "react-router";
import { backendBaseUrl } from "../Constants/constants";
import { auth } from "../firebase";

export default function SplashPage() {
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    const spotifyRefreshToken = localStorage.getItem("spotify_refresh_token");
    const spotifyId = localStorage.getItem("spotify_id");

    if (loading) {
      return;
    }

    if (
      spotifyRefreshToken &&
      spotifyRefreshToken !== "undefined" &&
      spotifyId
    ) {
      history.push("/list/" + spotifyId);
    } else if (user) {
      window.location.href = `${backendBaseUrl}/redirect`;
    } else {
      history.push("/landing");
    }
  }, [user, loading, history]);

  return (
    <div className="h-screen w-full flex justify-center items-center">
      <IoMusicalNotes
        color={"#ffffff"}
        title={"search"}
        className="h-24 w-24"
      />
    </div>
  );
}
