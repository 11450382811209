// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCiM-0qZ1jRxSgO41zZP_veCm7o9Zmpb98",
  authDomain: "album-listen-list.firebaseapp.com",
  projectId: "album-listen-list",
  storageBucket: "album-listen-list.appspot.com",
  messagingSenderId: "394158870093",
  appId: "1:394158870093:web:23f7c75d2b8c5a95061604",
  measurementId: "G-QZ184F72NR",
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const auth = getAuth();
export const firestore = getFirestore();
export const analytics = getAnalytics();
