import React, { useRef } from "react";
import { IoCloseCircle, IoSearch } from "react-icons/io5";

type Props = {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
};

export default function SearchBar({ searchText, setSearchText }: Props) {
  const inputRef = useRef(null);

  return (
    <div className="flex items-center">
      <IoSearch
        title={"search"}
        className="mb-4 h-8 pl-2 w-8 bg-background-highlight text-text-subdued rounded-l-lg"
      />
      <input
        autoFocus
        ref={inputRef}
        className="w-full h-8 mb-4 pl-1 rounded-none bg-background-highlight text-text-subdued outline-none text-sm"
        type="text"
        value={searchText}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="What do you want to listen to?"
      />
      {searchText ? (
        <div className="mb-4 h-8 w-8 flex justify-center items-center pr-1 bg-background-highlight rounded-r-lg">
          <IoCloseCircle
            className="h-5 w-5 text-text-subdued"
            onClick={() => {
              setSearchText("");
              // @ts-ignore
              inputRef.current && inputRef.current.focus();
            }}
          />
        </div>
      ) : (
        <div className="mb-4 h-8 px-2 w-8 bg-background-highlight rounded-r-lg" />
      )}
    </div>
  );
}
