import React from "react";
import { IoTrash } from "react-icons/io5";
import Modal from "../Containers/Modal";
import { useHistory } from "react-router";
import { auth } from "../firebase";
import { trackSuccessfulAction } from "../Constants/mixpanel";

export default function DeleteAccountModal() {
  const history = useHistory();
  
  const deleteAccount = async () => {
    if (auth.currentUser) {
      trackSuccessfulAction("Deleted account");
      await auth.currentUser.delete();
      localStorage.clear();
      history.push("/");
    }
  };

  return (
    <Modal modalId="delete-modal">
      <p className="text-2xl text-text-base mb-2">Delete My Account</p>
      <p className="text-sm text-text-subdued mb-6">
        This action cannot be undone and your entire album list will be lost,
        are you sure you want to delete your account?
      </p>
      <div
        className="rounded-lg mb-6 flex items-center justify-start"
        onClick={deleteAccount}
      >
        <IoTrash title={"delete"} className={"h-5 w-5 text-red-500"} />
        <p className={"active:text-text-subdued ml-3 text-sm text-text-base"}>
          Yes, Permanently Delete my Account
        </p>
      </div>
    </Modal>
  );
}
