import * as Sentry from "@sentry/react";
import axios from "axios";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { backendBaseUrl } from "./constants";

export const persistValue = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const persistAlbum = async (album: any) => {
  await setDoc(
    doc(firestore, `users/${auth.currentUser!.uid}/listenList/${album.id}`),
    {
      name: album.name,
      artist: album.artists[0].name,
      spotify_url: album.external_urls.spotify,
      api_url: album.href,
      images: album.images,
      release_date: album.release_date,
      total_tracks: album.total_tracks,
      time_added: new Date().toISOString(),
    },
  );
};

export const persistAlbums = (albums: Album[]) => {
  persistValue("albums", JSON.stringify(albums));
};

export const fetchLocalValue = (key: string, fallback: string): string => {
  return localStorage.getItem(key) || fallback;
};

export const fetchLocalAlbums = (): Album[] => {
  return JSON.parse(fetchLocalValue("albums", "[]"));
};

export const deleteAlbum = async (
  albumId: string,
  albums: Album[],
  setAlbums: React.Dispatch<React.SetStateAction<Album[]>>,
) => {
  deleteDoc(
    doc(firestore, `users/${auth.currentUser?.uid}/listenList`, albumId),
  );
  const newAlbums = albums.filter((a: any) => a.id !== albumId);
  persistValue("albums", JSON.stringify(newAlbums));
  setAlbums(newAlbums);
};

export const getOS = () => {
  if (/android/i.test(navigator.userAgent)) {
    return "Android";
  } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return "iOS";
  } else {
    return "Desktop";
  }
};

export const sortAlbums = (
  albums: Album[],
  field: SortByOptions,
  direction: string,
) => {
  return [...albums].sort((album1, album2) => {
    const a = album1[field]?.toString() || "";
    const b = album2[field]?.toString() || "";

    return direction === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  });
};

export const getDirection = (
  newValue: SortByOptions,
  sortValue: SortByOptions,
  sortDirection: "asc" | "desc",
) => {
  if (newValue === sortValue) {
    return sortDirection === "asc" ? "desc" : "asc";
  } else if (newValue === "time_added" || newValue === "release_date") {
    return "desc";
  } else {
    return "asc";
  }
};

export const searchAlbums = (searchText: string, albums: Album[]) => {
  return albums.filter(
    (a: any) =>
      a.name.toLowerCase().includes(searchText.toLowerCase()) ||
      a.artist.toLowerCase().includes(searchText.toLowerCase()),
  );
};

export const filterAlbums = (filterOption: FilterType, albums: Album[]) => {
  if (filterOption === "all") {
    return albums;
  } else if (filterOption === "not-reviewed") {
    return albums.filter((a: any) => !a.rating);
  } else if (filterOption === "reviewed") {
    return albums.filter((a: any) => a.rating);
  } else {
    return [];
  }
};

export const showModal = (modalId: string) => {
  (document.getElementById(modalId) as HTMLFormElement).showModal();
};

export const getRefreshToken = async (spotifyRefreshToken: string) => {
  try {
    const res = await axios.get(`${backendBaseUrl}/refresh_token`, {
      params: { refresh_token: spotifyRefreshToken },
    });
    return res.data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 400) {
      return null;
    } else {
      Sentry.captureException(e, {
        tags: { api: `${backendBaseUrl}/refresh_token` },
      });
      console.error(e);
      return null;
    }
  }
};

export const fetchRemoteAlbums = async (uid: string) => {
  const sort = fetchLocalValue("sortValue", "time_added");
  const directionValue = fetchLocalValue("sortDirection", "desc");
  const direction = directionValue === "asc" ? "asc" : "desc";

  const d = await getDocs(
    query(
      collection(firestore, `users/${uid}/listenList`),
      orderBy(sort, direction),
    ),
  );

  const remoteAlbums = d.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as Album),
  );

  return remoteAlbums;
};

export const getRandomAlbumId = (filteredAlbums: Album[]) => {
  const randomIndex = Math.floor(Math.random() * filteredAlbums.length);
  return filteredAlbums[randomIndex].id;
};
