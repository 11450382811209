import React from "react";
import { BsSpotify } from "react-icons/bs";
import { IoTrash } from "react-icons/io5";
import { useParams } from "react-router";
import { deleteAlbum } from "../Constants/helpers";
import { trackButtonClick } from "../Constants/mixpanel";
import Modal from "../Containers/Modal";
import { auth } from "../firebase";
import StarRating from "./StarRating";

type Props = {
  album: Album;
  albums: Album[];
  setAlbums: React.Dispatch<React.SetStateAction<Album[]>>;
};

export default function AlbumModal({ album, albums, setAlbums }: Props) {
  const { uid } = useParams<{ uid: string }>();

  const ModalButton = ({ children, onClick }: any) => {
    return (
      <div
        className="rounded-lg mb-6 flex items-center justify-start cursor-pointer"
        onClick={onClick}
      >
        {children}
      </div>
    );
  };

  return (
    <Modal modalId={`modal-${album.id}`}>
      <div className="flex items-center mb-4">
        <img
          src={album.images[1].url}
          width="70"
          height="70"
          alt=""
          className="mr-3 cursor-pointer"
        />
        <div>
          <p className={"text-base text-text-base mr-6"}>{album.name}</p>
          <p className={"text-sm text-text-subdued"}>{album.artist}</p>
          <p className={"text-sm text-text-subdued"}>
            {new Date(album.release_date).toDateString().slice(4, 15)}
          </p>
        </div>
      </div>
      <StarRating album={album} albums={albums} setAlbums={setAlbums} />
      <ModalButton
        onClick={() => {
          trackButtonClick("Album modal spotify button clicked", {
            album: { name: album.name, artist: album.artist, id: album.id },
          });
          window.location.href = album.spotify_url;
        }}
      >
        <BsSpotify title={"spotify"} className="h-5 w-5 text-spotify-green" />
        <p className="active:text-text-subdued ml-3 text-sm text-text-base">
          Spotify
        </p>
      </ModalButton>
      {auth.currentUser?.uid === uid && (
        <ModalButton
          onClick={() => {
            trackButtonClick("Album modal delete button clicked", {
              album: { name: album.name, artist: album.artist, id: album.id },
            });
            deleteAlbum(album.id, albums, setAlbums);
          }}
        >
          <IoTrash title={"delete"} className={"h-5 w-5 text-red-500"} />
          <p className={"active:text-text-subdued ml-3 text-sm text-text-base"}>
            Delete
          </p>
        </ModalButton>
      )}
    </Modal>
  );
}
