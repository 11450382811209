import mixpanel from "mixpanel-browser";

export const trackButtonClick = (action: string, params?: object) => {
  mixpanel.track(Events.BUTTON_CLICK, { ...params, action });
};

export const trackModalOpen = (modal: string, params?: object) => {
  mixpanel.track(Events.MODAL_OPEN, { ...params, modal });
};

export const trackSuccessfulAction = (action: string, params?: object) => {
  mixpanel.track(Events.SUCCESSFUL_ACTION, { ...params, action });
};

export const Events = {
  BUTTON_CLICK: "Button Click",
  MODAL_OPEN: "Modal Open",
  SUCCESSFUL_ACTION: "Successful Action",
};
