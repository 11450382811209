import React, { useContext, useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { useParams } from "react-router";
import Album from "../Components/Album";
import AlbumModal from "../Components/AlbumModal";
import { AppContext } from "../Components/AppContext";
import FilterButtons from "../Components/FilterButtons";
import ListEmptyState from "../Components/ListEmptyState";
import ListHeader from "../Components/ListHeader";
import OptionsModal from "../Components/OptionsModal";
import SearchBar from "../Components/SearchBar";
import {
  deleteAlbum,
  fetchRemoteAlbums,
  filterAlbums,
  persistAlbums,
  searchAlbums,
  showModal,
} from "../Constants/helpers";
import { trackButtonClick, trackModalOpen } from "../Constants/mixpanel";
import Page from "../Containers/Page";
import { auth } from "../firebase";

export default function ListPage() {
  const { uid } = useParams<{ uid: string }>();
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { albums, setAlbums, filterOption, setFilterOption } =
    useContext(AppContext);

  useEffect(() => {
    if (!showSearch) {
      setSearchText("");
    }
  }, [showSearch]);

  useEffect(() => {
    fetchRemoteAlbums(uid).then((remoteAlbums) => {
      persistAlbums(remoteAlbums);
      setAlbums(remoteAlbums);
    });
  }, [setAlbums, uid]);

  return (
    <Page>
      <div className="flex justify-between mb-4">
        <p className="text-2xl text-text-base w-full">Listen List</p>
        <ListHeader
          albums={albums}
          filterOption={filterOption}
          setShowSearch={setShowSearch}
        />
      </div>
      {showSearch && (
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      )}
      {albums.length > 0 && (
        <FilterButtons
          filterOption={filterOption}
          setFilterOption={setFilterOption}
        />
      )}
      {searchAlbums(searchText, filterAlbums(filterOption, albums))?.map(
        (album: Album, i: number) => (
          <div key={i}>
            <div className="flex items-center justify-between">
              <button
                className="w-full"
                onClick={() => {
                  showModal(`modal-${album.id}`);
                  trackModalOpen("Album", {
                    album: {
                      name: album.name,
                      artist: album.artist,
                      id: album.id,
                    },
                  });
                }}
              >
                <Album
                  image={album.images[1].url}
                  name={album.name}
                  artist={album.artist}
                  rating={album?.rating}
                />
              </button>
              {auth.currentUser?.uid === uid && (
                <IoCloseOutline
                  color={"#666666"}
                  title={"search"}
                  className="h-6 w-6 cursor-pointer"
                  onClick={() => {
                    trackButtonClick("Delete album", {
                      album: {
                        name: album.name,
                        artist: album.artist,
                        id: album.id,
                      },
                    });
                    deleteAlbum(album.id, albums, setAlbums);
                  }}
                />
              )}
            </div>
            <AlbumModal album={album} albums={albums} setAlbums={setAlbums} />
          </div>
        ),
      )}
      {auth.currentUser !== null && albums.length === 0 && <ListEmptyState />}
      <OptionsModal albums={albums} setAlbums={setAlbums} />
    </Page>
  );
}
