import React from "react";
import { BsSpotify } from "react-icons/bs";
import { IoApps } from "react-icons/io5";
import InstallModal from "../Components/InstallModal";
import { showModal } from "../Constants/helpers";
import { trackButtonClick, trackModalOpen } from "../Constants/mixpanel";
import { backendBaseUrl } from "../Constants/constants";

export default function LandingPage() {
  return (
    <div className="max-w-md mx-auto flex flex-col items-center pt-20 pb-10 px-8">
      <p className="text-4xl text-text-base mb-8">Album Listen List</p>
      <p className="text-text-subdued mb-8 text-center">
        Collect all the albums you plan to listen to in one convenient location.
      </p>
      <div className="mockup-phone mx-4 mb-8">
        <div className="camera" />
        <div className="display">
          <div className="artboard artboard-demo w-64">
            <img alt="" src="/screenshot.png" />
          </div>
        </div>
      </div>
      <a
        href={`${backendBaseUrl}/redirect`}
        className="btn bg-spotify-green border-none active:brightness-50 hover:bg-spotify-green text-black flex items-center rounded-full w-full mb-4"
        onClick={() => {
          trackButtonClick("Sign in with Spotify");
        }}
      >
        <p className="mr-2">Sign in with Spotify</p>
        <BsSpotify title={"spotify"} className="h-4 w-4" />
      </a>
      <button
        className="btn btn-outline bg-background-base hover:bg-background-base active:bg-background-press rounded-full w-full flex items-center text-text-base"
        onClick={() => {
          showModal("install-modal");
          trackModalOpen("Install App");
        }}
      >
        <p className="mr-2">Install App</p>
        <IoApps title="app" className="h-4 w-4" />
      </button>
      <footer className="footer footer-center p-4 text-gray-400 mt-16">
        <aside>
          <p>
            Built by{" "}
            <a
              className="link"
              href="https://josh.work"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackButtonClick("Open Josh Richards personal website");
              }}
            >
              Josh Richards
            </a>
          </p>
        </aside>
      </footer>
      <InstallModal />
    </div>
  );
}
