import React from "react";

type Props = {
  children: React.ReactNode;
  modalId: string;
  size?: "medium" | "large";
};

export default function Modal({ children, modalId, size = "medium" }: Props) {
  const modalHeight = size === "medium" ? "h-80" : "h-[450px]";

  return (
    <dialog id={modalId} className="modal modal-bottom sm:modal-middle">
      <div className={`modal-box relative flex flex-col bg-background-highlight ${modalHeight}`}>
        <form method="dialog">
          <button className="absolute text-text-subdued right-6 top-6">
            ✕
          </button>
        </form>
        {children}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
