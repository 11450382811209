import React, { ReactNode, createContext, useEffect, useState } from "react";
import { fetchLocalAlbums, fetchLocalValue } from "../Constants/helpers";

interface AppContextType {
  profile: Profile | null;
  setProfile: React.Dispatch<React.SetStateAction<any>>;
  albums: Album[];
  setAlbums: React.Dispatch<React.SetStateAction<Album[]>>;
  filterOption: FilterType;
  setFilterOption: React.Dispatch<React.SetStateAction<FilterType>>;
}

export const AppContext = createContext<AppContextType>({
  profile: null,
  setProfile: () => {},
  albums: [],
  setAlbums: () => {},
  filterOption: "loading",
  setFilterOption: () => {},
});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [albums, setAlbums] = useState<Album[]>([]);
  const [filterOption, setFilterOption] = useState<FilterType>("loading");
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const localAlbums = fetchLocalAlbums();
    const localFilterType = fetchLocalValue(
      "filterOption",
      "all",
    ) as FilterType;
    const localProfile = JSON.parse(fetchLocalValue("profile", "null"));

    setAlbums(localAlbums);
    setFilterOption(localFilterType);
    setProfile(localProfile);
  }, []);

  return (
    <AppContext.Provider
      value={{
        albums,
        setAlbums,
        filterOption,
        setFilterOption,
        profile,
        setProfile,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
