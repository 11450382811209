import React from "react";
import { IoMusicalNotes } from "react-icons/io5";
import { useHistory } from "react-router";
import { trackButtonClick } from "../Constants/mixpanel";

export default function ListEmptyState() {
  const history = useHistory();

  return (
    <div className="flex flex-col items-center justify-center h-96">
      <IoMusicalNotes
        title={"noAlbums"}
        className="h-28 w-28 text-text-subdued mb-4"
      />
      <p className="text-sm text-text-subdued w-60 text-center mb-6">
        No albums in your listen list
      </p>
      <button
        onClick={() => {
          trackButtonClick("List page empty state add album button clicked");
          history.push("/search");
        }}
        className="btn btn-outline btn-sm text-spotify-green rounded-full"
      >
        Add an Album
      </button>
    </div>
  );
}
